/*
 * File: RouterPathResolver.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import { RouterPathResolverType } from "Config/Router/RouterPathResolver.type";

/**
 * Router description of all the different paths
 */
const RouterPathResolver: RouterPathResolverType = {
  // Do not change the next line, configure it via the ROUTE_BY_ROLES above
  pathEntryPoint: { path: "/" },
  routeContact: { path: "/contact" },
  routeForgotPassword: { path: "/forgotpassword" },
  routeNewPassword: { path: "/newPassword/:token" },
  routeUser: { path: "/user" },
  routeVerifyUser: { path: "/verify_user/:token" },
  routeLegal: { path: "/legal" },
  routeCars: { path: "/cars" },
  routeCarsId: { path: "/cars/:id" },
  routeCarsBrand: { path: "/cars_search/:brandName" },
  routeFavorites: { path: "/favorites" },
  routeCart: { path: "/cart/" },
  routeBuyback: { path: "/buyback" },
  routeCgv: { path: "/cgv" },
  routeRgpd: { path: "/rgpd" },
  routeAbout: { path: "/about" },
  routeFinance: { path: "/finance" },
  routeFinanceFaq: { path: "/faq_finance" },
  routeLogin: { path: "/login" },
  routeProfil: { path: "/profil" },
  routeSignIn: {path: "/signin"},
};

export default RouterPathResolver;
