/*
 * File: CarList.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:45:24
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd February 2023 09:16:18
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import styles from "Content/Car/CarList/CarList.module.css";
import { useEffect, useRef, useState, Fragment } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import translator from "MultiLanguage/TranslationWrapper";
import OrderByHolder from "Content/OrderBy/OrderByHolder/OrderByHolder";
import { TextFilter } from "Content/Filters/TextFilter/TextFilterHolder/TextFilterHolder";
import { SearchParamsEquality, GetCurrentCarPage, GetFilters, GetOrderBy } from "Utils/UrlParams";
import { useMyContext } from "Content/Main/Main";
import PaginateStyled from "Theme/Component/PaginateStyled";
import CarsDisplay from "Content/Car/CarsDisplay/CarsDisplay";
import ButtonFilter from "Content/Filters/ButtonFilter/ButtonFilter";
import { useAuth } from "Router/AuthProvider";

type CarListType = {
    custom: boolean,
    page?: number,
    filters?: string[] | null,
    orderby?: {name: string, order: string},
};

export function CarList(props: CarListType) {
    const carsLoaded: {nb_page: number, cars: [], brand: string} = useLoaderData() as {nb_page: number, cars: [], brand: string};
    const [carListMaj, setCarListMaj] = useState([]);
    const [curPage, setCurPage] = useState(GetCurrentCarPage);
    const [nbPage, setNbPage] = useState(-1);
    const location = useLocation();
    const [myResetPage, setResetPage] = useState(false);
    const [myFilters, setMyFilters] = useState(GetFilters(location.search));
    const [myFilterChange, setMyFilterChange] = useState(false);
    const [orderBy, setOrderBy] = useState(GetOrderBy);
    const firstLoad = useRef(true);
    const [mySearchParams, setMySearchParams] = useState(new URLSearchParams(location.search));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const { isConnected } = useAuth();

    const { setterThemeColor } = useMyContext();

    // Set ThemeColor
    useEffect(() => {
        if (myFilters.length > 0)
            setterThemeColor(myFilters[0]);
        else
            setterThemeColor("");
    }, [myFilters.length]);

    // Navigate on mySearchParams
    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        if (!SearchParamsEquality(mySearchParams, searchParams))
            navigate("?" + mySearchParams.toString());
    }, [mySearchParams]);

    useEffect(() => {
        const handleWinResize = () => {
            const actualWidth = window.innerWidth;
            if (actualWidth <= 882 && windowWidth > 882){
                setWindowWidth(actualWidth);
                firstLoad.current=true;
            }
            if (actualWidth >= 883 && windowWidth < 883){
                setWindowWidth(actualWidth);
                firstLoad.current=true;
            }
        };
        window.addEventListener("resize", handleWinResize);
        return () => window.removeEventListener("resize", handleWinResize);
    }, [windowWidth]);

    // Set Cars to display
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if(carsLoaded != undefined) {
            setCarListMaj(carsLoaded.cars);
            setNbPage(carsLoaded.nb_page);
        }
        if(!searchParams.has('page'))
            setCurPage(0);
        else
            setCurPage(parseInt(searchParams.get("page")!)-1);
    }, [carsLoaded]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
        } else if (myFilterChange) {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has("filters")) {
                if (myFilters.length > 0)
                    searchParams.set("filters", myFilters.toString());
                else
                    searchParams.delete("filters");
            } else if (myFilters.length > 0){
                searchParams.set("filters", myFilters.toString());
                searchParams.set("page", "1");
            }
            if (myResetPage){
                searchParams.set("page", "1");
                setCurPage(0);
            }
            if (!SearchParamsEquality(mySearchParams, searchParams)){
                setMySearchParams(searchParams);
            }
        }
        setMyFilterChange(false);
    }, [myFilters]);

    useEffect(()=> {
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.has('orderedby'))
            setOrderBy({name: "", order: ""});

        if (!searchParams.has('filters'))
            setMyFilters([]);

        if (!SearchParamsEquality(mySearchParams, searchParams))
            setMySearchParams(searchParams);
    }, [location]);

    const handlePageClick = async (event: {selected: number}) =>{
        window.scrollTo(0,0);
        setCurPage(event.selected);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", (event.selected + 1).toString());

        if (!SearchParamsEquality(mySearchParams, searchParams))
            setMySearchParams(searchParams);
    };

    const handleFilterChange = async (filters: string[], resetPage: boolean) => {
        if (!firstLoad.current){
            setResetPage(resetPage);
            setMyFilters(filters);
            setMyFilterChange(true);
            if (filters.length > 0) {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("filters", filters.toString());
                if (!SearchParamsEquality(mySearchParams, searchParams)){
                    setMySearchParams(searchParams);
                }
            }
        }
        else
            firstLoad.current = false;
    };

    const handleOrderByChange = async (orderBy: string)=> {
        const searchParams = new URLSearchParams(location.search);
        const orderByReq = orderBy.split(",");
        setOrderBy({name: orderByReq[0], order: orderByReq[1]});
        if (!firstLoad.current){
            if (orderByReq[1] !== "notActivated") {
                searchParams.set("orderedby", orderBy);
                searchParams.set("page", "1");
            } else if (searchParams.has("orderedby"))
                searchParams.delete("orderedby");
            setCurPage(0);
            if (!SearchParamsEquality(mySearchParams, searchParams))
                setMySearchParams(searchParams);
        }
    };

    return (
        <Fragment>
            <div className={`${styles.filterOrderByHolder}`}>
                {
                    windowWidth < 883 ?
                        <div className={`${styles.filters}`}>
                            <TextFilter id="Filters" curFilters={myFilters} filtersSelected={handleFilterChange} themeColor={myFilters[0]}/>
                        </div>
                        : null
                }
                {
                    isConnected ?
                        <div className={`${styles.orderBy}`}>
                            <OrderByHolder id="Orderby" curOrderBy={orderBy} getCurrentlyActiveAndOrder={handleOrderByChange} themeColor={myFilters[0]}/>
                        </div>
                        : null
                }
            </div>
            <div className={`${styles.carListHolder}`}>
                {
                    windowWidth > 882 ?
                    <ButtonFilter setFilters={handleFilterChange} curFilters={myFilters} /> : null
                }
                <CarsDisplay carList={carListMaj} themeColor={myFilters[0]}/>
            </div>
            <div>
                { nbPage > -1 ?
                    <PaginateStyled
                        bgColor={myFilters[0]}
                        className={`${styles.pagination}`}
                        // activeClassName={`${styles.activeClassName}`}
                        // pageClassName={`${styles.pageClassName}`}
                        breakClassName={`${styles.breakClassName}`}
                        previousClassName={`${styles.previousClassName}`}
                        // previousLinkClassName={`${styles.textLinkClassName}`}
                        // nextLinkClassName={`${styles.textLinkClassName}`}
                        disabledClassName={`${styles.disabledClassName}`}
                        pageLinkClassName="page-link"
                        disabledLinkClassName={`${styles.disabledLinkClassName}`}
                        forcePage={curPage}
                        breakLabel="..."
                        onPageChange={(e: any) => { handlePageClick(e); }}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        pageCount={nbPage}
                        nextLabel={translator.decode("pagination.next")}
                        previousLabel={translator.decode("pagination.previous")}
                        renderOnZeroPageCount={undefined}
                        />
                        : null
                 }
            </div>
        </Fragment>
    );
}

export default CarList;
