/*
 * File: AdressContact.tsx
 * Project: autofi-front
 * File Created: Thursday, 2nd March 2023 09:46:48
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 24th March 2023 09:27:32
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { ContactSelect } from "Content/Contact/ContactSelect/ContactSelect";
import translator from "MultiLanguage/TranslationWrapper";
import { useEffect, useRef, useState } from "react";
import { getAddresses } from "Services/Address/AddressService";
import CreatableSelect from 'react-select/creatable';
import styles from "Content/Contact/AddressContact/AddressContact.module.css";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";
import { ActionMeta, InputActionMeta, SingleValue } from "react-select";
import depList from "Content/Contact/AddressContact/DepartmentListe";
import { AddressContactProps } from "Content/Contact/AddressContact/AddressContactProps.type";
import { Address } from "Content/Contact/AddressContact/AddressInterface";

export function AddressContact(props: AddressContactProps) {
  const [selectedAdress, setSelectedAdress] = useState<OptionType | null>(null);
  const [addressOptions, setAddressOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adressCity, setAdressCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [badPostalCode, setBadPostalCode] = useState(false);
  const [adressDepartment, setAdressDepartment] = useState<OptionType>({label: translator.decode("contact.stateSelect"), value: ""});
  const [departmentOptions, setDepartmentOptions] = useState<OptionType[]>(depList);
  const curChar = useRef(0);

  const inputChanges = (input: string, action: InputActionMeta) => {
    const tmp = input.trim();
    if (tmp && tmp.length > 3 && tmp.length != curChar.current) {
      curChar.current = input.length;
      setIsLoading(true);
      getAddresses(input).then((val) => {
        if (val && val.length > 0) {
          setAddressOptions(val);
          setIsLoading(false);

        } else {
          setAddressOptions([{label: input, value: input}])
          setIsLoading(false);
        }
      }).catch((reason: any) => {
        setAddressOptions([{label: input, value: input}])
        setIsLoading(false);
      });
    }
  };

  const filterOptions = (_: { label: string; value: string; data: any },__: string) => {
    return true;
  };

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    const newOption = {label: inputValue, value: inputValue};
    setAddressOptions((_) => [...addressOptions, newOption]);
    setIsLoading(false);
    setSelectedAdress(newOption);
    props.setAddress();
  };

  useEffect(() => {
    setBadPostalCode(false);
    if (!/\d*/.test(postalCode)) {
      setBadPostalCode(true);
      props.enableNext(false);
      return;
    }
    if (postalCode.length == 5) {
      props.enableNext(true);
      return;
    }
    else if (postalCode.length !== 0)
      setBadPostalCode(true);
    props.enableNext(false);
  }, [postalCode]);

  const handleSelection = (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    setSelectedAdress(newValue != null ? {label: `${newValue.data.houseNumber ?? ""} ${newValue.data.street} - ${newValue.data.city}`, value: newValue.value} : {label: "", value: ""});
    setAdressCity(newValue != null && newValue.data ? newValue.data.city : {label: "", value: ""});
    setPostalCode(newValue?.data.postalCode);
    setAdressDepartment(newValue != null && newValue.data ? departmentOptions.find((x) => newValue.data.postalCode.startsWith(x.value)) as OptionType : {label: "", value: ""});
    const addr: Address = {
      postalCode: newValue?.data.postalCode,
      city: newValue?.data.city,
      houseNumber: newValue?.data.houseNumber,
      street: newValue?.data.street,
      score: 100,
      country: ""
    }
    props.setAddress(addr);
  };

  return (
    <>
      <div className={`${styles.addressBlock}`}>
        {/* <label className={`${styles.input}`} htmlFor="address">{translator.decode("contact.address")}:</label> */}
        <CreatableSelect isLoading={isLoading} onChange={handleSelection} onInputChange={inputChanges} filterOption={filterOptions}
                          className={`${styles.input} ${styles.addressSize} ${styles.inputSelect}`} id="address" value={selectedAdress}
                          onCreateOption={handleCreate} options={addressOptions} isSearchable={true} placeholder={<div>{translator.decode("contact.addressSelect")}</div>}
                          styles={{
                            control: (provided: any, state: any) => ({
                              ...provided,
                              boxShadow: "none",
                              borderRadius: 0,
                              border: "none",
                              borderBottom: "solid 1px var(--theme-color) !important"
                            }),
                            menu: (provided: any, state: any) => ({
                              ...provided,
                              border: "none",
                              boxShadow: "0 0 0 1px var(--theme-color)",
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              backgroundColor: state.isFocused && "var(--theme-color)",
                              color: state.isFocused && "var(--theme-bg-color)",
                            })
                          }} />
      </div>
      <div className={`${styles.lineBlock}`}>
        <div className={`${styles.inputBlock}`}>
          {/* <label className={`${styles.input}`} htmlFor="state">{translator.decode("contact.state")} :</label> */}
          <ContactSelect className={`${styles.input} ${styles.inputSelect} ${styles.addressSize}`} id="state" value={adressDepartment} onChange={(e: OptionType) => {setAdressDepartment(e);}} options={departmentOptions} />
        </div>
        <div className={`${styles.inputBlock}`}>
          {/* <label className={`${styles.input}`} htmlFor="postalCode">{translator.decode("contact.postalCode")}<span className={styles.mandatory}>*</span> :</label> */}
          <div style={{display:"flex"}}>
            <input className={`${styles.input} ${styles.inputSize} ${styles.addressSize} ${badPostalCode ? styles.wrongInput : null}`} id="postalCode" type="text" name="postalCode" value={postalCode} onChange={(e) => {setPostalCode(e.target.value); props.address.postalCode = e.target.value; props.setAddress(props.address);}} placeholder={translator.decode("contact.postalCodeSelect")} />
            <span className={styles.mandatory}>*</span>
          </div>
          {
            badPostalCode ? <p className={styles.mandatory}>{translator.decode("contact.wrong.postalCode")}</p> : null
          }
        </div>
        <div className={`${styles.inputBlock}`}>
          {/* <label className={`${styles.input}`} htmlFor="city">{translator.decode("contact.city")}:</label> */}
          <input className={`${styles.input} ${styles.inputSize} ${styles.addressSize}`} id="city" type="text" name="City" value={adressCity} onChange={(e) => {setAdressCity(e.target.value); props.address.city = e.target.value; props.setAddress(props.address);}} placeholder={translator.decode("contact.city")} />
        </div>
      </div>
    </>
  );
}
