/*
 * File: CarService.ts
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:55:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 02:45:37
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Axios from "axios";
import Config from "Config/Config";
import UserSession from "Utils/UserSession";

type RespSingleContactReqDbType = {
  id: number;
  CreatedAt: Date;
  Gender?: string;
  FirstName?: string;
  LastName?: string;
  Email: string;
  Phone?: string;
  HouseNumber?: string;
  Street?: string;
  PostalCode?: string;
  City?: string;
  Category?: string;
  Energy?: string;
  Brand?: string;
  Model?: string;
  Mileage?: string;
  Comment?: string;
  message?: string;
};

type ContactRequest = {
  carIntels?: {
    id?: number;
    category?: string;
    energy?: string;
    brand?: string;
    model?: string;
    mileage?: number;
  };
  personnalIntel: {
    gender?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: {
      houseNumber?: string;
      street?: string;
      postalCode: string;
      city?: string;
    };
  };
  comments: {
    text: string;
  };
};

export default class ContactService {
  static async addNewContactRequest(contact: ContactRequest): Promise<RespSingleContactReqDbType | null> {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.POST_REQUEST_CONTACT}`;
    return Axios.post(url, contact, UserSession.buildAxiosHeader());
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(contact),
    // };
    // const res = await fetch(
    //   `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.POST_REQUEST_CONTACT}`,
    //   requestOptions
    // );
    // if (!res.ok) return null;
    // return await res.json();
  }
}
